<template>
	
	<div class="dropdown px-2">
		<button class="btn btn-link text-decoration-none fw-bold px-0 d-flex align-items-center" type="button" id="background-menu" data-bs-toggle="dropdown" aria-expanded="false">
			<span v-if="get_block_option() && is_color" style="width: 20px; height: 20px;" class="me-2 rounded border border-primary" :style="'background: ' + get_block_option() + ';'"></span>
			{{ label }}
			<span class="material-icons-outlined ms-2">expand_more</span>
		</button>
		<ul class="dropdown-menu" aria-labelledby="background-menu">
			<li v-for="(color) in options" :key="'color-switch-' + color.key">
				<button class="dropdown-item d-flex align-items-center justify-content-between" @click="submit((prepend ? prepend : '') + color.value)">
					<span class="d-flex align-items-center">
						<span v-if="color.value && is_color" style="width: 20px; height: 20px;" class="me-2 rounded border border-primary" :style="'background: ' + get_color_option( color.value ) + ';'"></span>
						{{ color.name }}
					</span>
					<span v-if="get_block_value() == ((prepend ? prepend : '') + color.value)" class="material-icons-outlined">check</span>
				</button>
			</li>
		</ul>
	</div>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'components.color_dropdown',
	props: {
		modelValue: [String, Number, Object, Boolean, Array],
		site: Object,
		prepend: String,
		label: String,
		block_key: String,
		attr_key: String,
		options: Array,
		is_color: Boolean
	},
	emits: [
		'update:modelValue',
	],
	data() {
		return {
			error: {},
			loading: false,
		}
	},
	components: {
		
	},
	computed: {

		...mapGetters( 'user', ['auth_user']),

		value: {
			get() {
				return this.modelValue
			},
			set( value ) {
				this.$emit('update:modelValue', value)			
			}
		},

	},
	mounted()
	{
		
	},
	methods: {
		reset_error()
		{
			this.error = {}
		}, 

		async submit( value )
		{
			this.$emit( 'success', value )
		},

		get_block_value()
		{
			if ( this.site.settings['blocks'] && this.site.settings['blocks'][this.block_key] && this.site.settings['blocks'][this.block_key][this.attr_key] ) {
				return this.site.settings['blocks'][this.block_key][this.attr_key];
			}

			return ''
		},

		get_block_option()
		{
			if ( this.site.settings['blocks'] && this.site.settings['blocks'][this.block_key] && this.site.settings['blocks'][this.block_key][this.attr_key] ) {
				var theme = this.site.settings['blocks'][this.block_key][this.attr_key];

				theme = theme.replace( 'aaio-block-theme-', '' );

				if ( this.site.settings[theme] ) {
					return this.site.settings[theme]
				}
				return '#ffffff';
			}

			return ''
		},

		get_color_option( theme )
		{
			theme = theme.replace( 'aaio-block-theme-', '' );

			if ( this.site.settings[theme] ) {
				return this.site.settings[theme]
			}
			return '#ffffff';
		}
	}
}
</script>

<style>

</style>
