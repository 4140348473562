<template>

	<div v-if="site" class="w-100 h-100 d-flex align-items-center mx-auto">

		<div class="h-100 d-flex flex-column align-items-center justify-content-center overflow-hidden border-end p-4" style="flex: 0 0 500px;">
			
			<div class="w-100 h-75 d-flex flex-column align-items-center justify-content-center">

				<div class="setup-chat border rounded-top p-4 overflow-auto mx-auto w-100" ref="scrollContainer" style="max-width: 600px; height: calc( 100% - 90px );">
					
					<div>						
						
						<chat_message :content="'👋 Hey ' + first_name + '! Leo here and I\'m going to help get your site designed.'" type="1" author="2" />
						<chat_message :content="'First, collate ' + site.name + '\'s branding. Upload their logos and inform me when you\'re ready to proceed. If you don\'t have the logos, click Next step.'" type="1" author="2" />

						<chat_message v-if="site.settings && site.settings['brand_logos_complete']" :content="'Next step'" type="2" author="4" :include_edit="true" @edit="set_active_step( 'brand_logos' )" />

						<chat_message v-if="site.settings && site.settings['brand_logos_complete']" :content="'Perfect! Now can you add their colour scheme? You can add up to 8 colours and each colour will be available when customising your designs.'" type="1" author="2" />

						<chat_message v-if="site.settings && site.settings['colors_complete']" :content="'Next step'" type="2" author="4" :include_edit="true" @edit="set_active_step( 'colors' )" />

						<chat_message v-if="site.settings && site.settings['colors_complete']" :content="'Great! Now can you tell me what fonts they use? You can specify different fonts for each heading and body style.'" type="1" author="2" />

						<chat_message v-if="site.settings && site.settings['fonts_complete']" :content="'Next step'" type="2" author="4" :include_edit="true" @edit="set_active_step( 'fonts' )" />

						<chat_message v-if="site.settings && site.settings['fonts_complete']" :content="'Now we have the brand it\'s time to create a consistent look and feel across the site.'" type="1" author="2" />

						<chat_message v-if="site.settings && site.settings['fonts_complete']" :content="'First, select the appropriate colour mode. This will automatically apply a background colour to each section and a contrasting colour for text and buttons to enhance accessibility. This eliminates the need to set colors for each new section, though you can override them later if needed. Use the arrows at the top right to navigate the options.'" type="1" author="2" />

						<chat_message v-if="site.settings && site.settings['color_mode_complete']" :content="'Next step'" type="2" author="4" :include_edit="true" @edit="set_active_step( 'color_mode' )" />

						<chat_message v-if="site.settings && site.settings['color_mode_complete']" :content="'Do you want to include a stylised divider between each block? You\'ll also be able to set different dividers on a section by section basis later.'" type="1" author="2" />

						<chat_message v-if="site.settings && site.settings['divider_complete']" :content="'Next step'" type="2" author="4" :include_edit="true" @edit="set_active_step( 'divider' )" />

						<chat_message v-if="site.settings && site.settings['divider_complete']" :content="'Now, lets choose a button style. There are a number of options, choose the one that best fits with ' + site.name + '\'s brand.'" type="1" author="2" />

						<chat_message v-if="site.settings && site.settings['style_complete']" :content="'Next step'" type="2" author="4" :include_edit="true" @edit="set_active_step( 'style' )" />

						<chat_message v-if="site.settings && site.settings['style_complete']" :content="'Great! The design system works by dragging and dropping \'blocks\' from our library on to a page. We\'ve got loads of blocks to choose from and each is fully customisable. We now need to choose the default settings for each block.'" type="1" author="2" />

						<chat_message v-if="site.settings && site.settings['style_complete']" :content="'So, choose the header layout you would like to use and customise the colours and layout. Let me know when you\'re ready to move to the next step.'" type="1" author="2" />

						<chat_message v-if="site.settings && site.settings['header_complete']" :content="'Next step'" type="2" author="4" :include_edit="true" @edit="set_active_step( 'header' )" />

						<chat_message v-if="site.settings && site.settings['header_complete']" :content="'Do the same for the hero block.'" type="1" author="2" />

						<chat_message v-if="site.settings && site.settings['hero_complete']" :content="'Next step'" type="2" author="4" :include_edit="true" @edit="set_active_step( 'hero' )" />

						<chat_message v-if="site.settings && site.settings['hero_complete']" :content="'We\'re on a roll! Update the content block next.'" type="1" author="2" />

						<chat_message v-if="site.settings && site.settings['content_complete']" :content="'Next step'" type="2" author="4" :include_edit="true" @edit="set_active_step( 'content' )" />

						<chat_message v-if="site.settings && site.settings['content_complete']" :content="'And now the accordion block.'" type="1" author="2" />

						<chat_message v-if="site.settings && site.settings['accordion_complete']" :content="'Next step'" type="2" author="4" :include_edit="true" @edit="set_active_step( 'accordion' )" />

						<chat_message v-if="site.settings && site.settings['accordion_complete']" :content="'And now the carousel block.'" type="1" author="2" />

						<chat_message v-if="site.settings && site.settings['carousel_complete']" :content="'Next step'" type="2" author="4" :include_edit="true" @edit="set_active_step( 'carousel' )" />

						<chat_message v-if="site.settings && site.settings['carousel_complete']" :content="'And now the contact block.'" type="1" author="2" />

						<chat_message v-if="site.settings && site.settings['block_contact_complete']" :content="'Next step'" type="2" author="4" :include_edit="true" @edit="set_active_step( 'block_contact' )" />

						<chat_message v-if="site.settings && site.settings['block_contact_complete']" :content="'And now the cta block.'" type="1" author="2" />

						<chat_message v-if="site.settings && site.settings['cta_complete']" :content="'Next step'" type="2" author="4" :include_edit="true" @edit="set_active_step( 'cta' )" />

						<chat_message v-if="site.settings && site.settings['cta_complete']" :content="'And now the form block.'" type="1" author="2" />

						<chat_message v-if="site.settings && site.settings['form_complete']" :content="'Next step'" type="2" author="4" :include_edit="true" @edit="set_active_step( 'form' )" />

						<chat_message v-if="site.settings && site.settings['form_complete']" :content="'And now the items block.'" type="1" author="2" />

						<chat_message v-if="site.settings && site.settings['items_complete']" :content="'Next step'" type="2" author="4" :include_edit="true" @edit="set_active_step( 'items' )" />

						<chat_message v-if="site.settings && site.settings['items_complete']" :content="'And now the logos block.'" type="1" author="2" />

						<chat_message v-if="site.settings && site.settings['logos_complete']" :content="'Next step'" type="2" author="4" :include_edit="true" @edit="set_active_step( 'logos' )" />

						<chat_message v-if="site.settings && site.settings['logos_complete']" :content="'And now the media block.'" type="1" author="2" />

						<chat_message v-if="site.settings && site.settings['media_complete']" :content="'Next step'" type="2" author="4" :include_edit="true" @edit="set_active_step( 'media' )" />

						<!-- <chat_message v-if="site.settings && site.settings['media_complete']" :content="'And now the people block.'" type="1" author="2" />

						<chat_message v-if="site.settings && site.settings['people_complete']" :content="'Next step'" type="2" author="4" :include_edit="true" @edit="set_active_step( 'people' )" /> -->

						<chat_message v-if="site.settings && site.settings['media_complete']" :content="'And now the posts carousel block.'" type="1" author="2" />

						<chat_message v-if="site.settings && site.settings['posts-carousel_complete']" :content="'Next step'" type="2" author="4" :include_edit="true" @edit="set_active_step( 'posts-carousel' )" />

						<chat_message v-if="site.settings && site.settings['posts-carousel_complete']" :content="'And now the posts grid block.'" type="1" author="2" />

						<chat_message v-if="site.settings && site.settings['posts-grid_complete']" :content="'Next step'" type="2" author="4" :include_edit="true" @edit="set_active_step( 'posts-grid' )" />

						<chat_message v-if="site.settings && site.settings['posts-grid_complete']" :content="'And now the tabs block.'" type="1" author="2" />

						<chat_message v-if="site.settings && site.settings['tabs_complete']" :content="'Next step'" type="2" author="4" :include_edit="true" @edit="set_active_step( 'tabs' )" />

						<chat_message v-if="site.settings && site.settings['tabs_complete']" :content="'And now the footer block.'" type="1" author="2" />

						<chat_message v-if="site.settings && site.settings['footer_complete']" :content="'Next step'" type="2" author="4" :include_edit="true" @edit="set_active_step( 'footer' )" />

						<chat_message v-if="site.settings && site.settings['footer_complete']" :content="'I think we\'re all set. If you\'re happy with the look and feel of the site, it\'s time for me to setup your designs and populate them with your content. Let me know when you\'re ready.'" type="1" author="2" />

						<chat_message v-if="site.settings && site.settings['design_complete']" :content="'Next step'" type="2" author="4" />
						
					</div>

				</div>

				<div class="setup-chat-actions border rounded-bottom border-top-0 overflow-hidden bg-white mx-auto w-100" style="max-width: 600px; height: 90px;">
					<form @submit.prevent="submit" class="d-flex align-items-center h-100">
						<div class="h-100 flex-grow-1">
							
							<div class="p-3">
								<btn_submit 
									v-if="site.settings && !site.settings['design_complete']"
									label="Next step" 
									icon="check"  
									:icon_after="true" 
									:loading="loading"
								/>
							</div>

						</div>
						<button :disabled="loading" v-if="['client_name', 'url'].includes( active_step )" class="bg-transparent border-0 text-primary h-100 d-flex align-items-center justify-content-center px-3 border-start" style="width: 90px;">
							<span v-if="!loading" class="material-icons-outlined">send</span>
							<div v-else class="spinner-border spinner-border-sm" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
						</button>
					</form>
				</div>

			</div>

		</div>

		<div class="h-100 align-items-center justify-content-center overflow-hidden d-flex position-relative rounded-end flex-grow-1">

			<div class="w-100 h-100 position-relative py-4" :class="['brand_logos', 'colors', 'fonts'].includes( active_step ) ? 'overflow-auto' : 'overflow-hidden'">

				<div class="container h-100">

					<div class="row h-100 justify-content-center mb-4">

						<div class="col-12 d-flex flex-column" :class="!['brand_logos', 'colors', 'fonts'].includes( active_step ) ? '' : 'col-xxl-11'">

							<div class="mb-4 d-flex align-items-center justify-content-between small">
								<div class="d-flex align-items-center flex-grow-1 me-4">
									<span class="me-4">
										Step {{ count }} of {{ total }}
									</span>

									<div class="progress flex-grow-1 mb-0 border">
										<div class="progress-bar" :style="'width: ' + percent + '%;'" :class="percent == 100 ? 'bg-success' : 'bg-info'">{{ percent }}%</div>
									</div>
								</div>

								<a v-if="site && site.id && !loading" data-bs-toggle="modal" data-bs-target="#skip" href="#skip" class="ms-4">
									Skip setup wizard
								</a>
								<div v-else-if="site && site.id && loading" class="spinner-border spinner-border-sm ms-4" role="status">
									<span class="visually-hidden">Loading...</span>
								</div>
							</div>

							<planning_page v-if="active_step == 'brand_logos'" step="brand_logos" :include_description="true" :include_comment="true" v-model="site" :page="count" :total="total" :team="team" :layout="3" />

							<planning_page v-if="active_step == 'colors'" step="colors" :include_description="true" :include_comment="true" v-model="site" :page="count" :total="total" :team="team" :layout="3" />

							<planning_page v-if="active_step == 'fonts'" step="fonts" :include_description="true" :include_comment="true" v-model="site" :page="count" :total="total" :team="team" :layout="3" />

							<div v-if="!['brand_logos', 'colors', 'fonts'].includes( active_step )" class="d-flex flex-grow-1 w-100 flex-column">
								<div class="border rounded w-100 overflow-hidden d-flex flex-column">
									<div v-if="!['complete'].includes( active_step )" class="border-bottom d-flex align-items-center justify-content-between bg-white">
										
										<div class="d-flex align-items-center">
											<div v-if="screens[active_screen]" class="dropdown p-3">
												<button class="btn btn-link fw-bold text-decoration-none px-0 d-flex align-items-center" type="button" id="screen-menu" data-bs-toggle="dropdown" aria-expanded="false">
													{{ screens[active_screen].name }}
													<span class="material-icons-outlined ms-2">expand_more</span>
												</button>
												<ul class="dropdown-menu" aria-labelledby="screen-menu">
													<li v-for="(screen, screen_key) in screens" :key="'screen-switch-' + screen.key">
														<a class="dropdown-item d-flex align-items-center justify-content-between" href="#" @click="set_active_screen( screen_key )">
															{{ screen.name }}
															<span v-if="screens[active_screen].key == screen.key" class="material-icons-outlined ms-2">check</span>
														</a>
													</li>
												</ul>
											</div>

											<div v-if="!['color_mode', 'divider', 'style', 'transition'].includes( active_step )" class="d-flex align-items-center border-start ps-2">

												<div v-if="!sub_menu" class="d-flex align-items-center">
													<button v-if="[ 'header', 'footer' ].includes( active_step )" @click="sub_menu = 'colors'" class="btn p-2 rounded-0 d-flex align-items-center justify-content-center border-0">
														<span class="material-icons-outlined me-2">palette</span>
														Colours
													</button>
													<button @click="sub_menu = 'layout'" class="btn p-2 rounded-0 d-flex align-items-center justify-content-center border-0">
														<span class="material-icons-outlined me-2">view_week</span>
														Layout
													</button>
												</div>
												<div v-else-if="sub_menu == 'colors'" class="d-flex align-items-center">
													<button @click="sub_menu = null" class="btn p-2 rounded-0 d-flex align-items-center justify-content-center border-0">
														<span class="material-icons-outlined me-2">west</span>
													</button>
													<color_dropdown 
													:site="site" 
													label="Background" 
													prepend="aaio-block-theme-"
													:block_key="'aaio/' + active_step"
													attr_key="block_theme"
													:options="colors"
													:is_color="true"
													@success="(e) => update_setting( 'aaio/' + active_step, 'block_theme', e )" 
													/>
													<color_dropdown 
													:site="site" 
													label="Text" 
													:block_key="'aaio/' + active_step"
													attr_key="block_text"
													:options="colors"
													:is_color="true"
													@success="(e) => update_setting( 'aaio/' + active_step, 'block_text', e )" 
													/>
													<color_dropdown 
													:site="site" 
													label="Button" 
													:block_key="'aaio/' + active_step"
													attr_key="block_buttons"
													:options="colors"
													:is_color="true"
													@success="(e) => update_setting( 'aaio/' + active_step, 'block_buttons', e )" 
													/>
												</div>

												<div v-else-if="sub_menu == 'layout'" class="d-flex align-items-center">
													<button @click="sub_menu = null" class="btn p-2 rounded-0 d-flex align-items-center justify-content-center border-0">
														<span class="material-icons-outlined me-2">west</span>
													</button>
													<color_dropdown 
													:site="site" 
													label="Container" 
													:block_key="'aaio/' + active_step"
													attr_key="block_container"
													:options="containers"
													@success="(e) => update_setting( 'aaio/' + active_step, 'block_container', e )" 
													/>
													<!-- <color_dropdown 
													v-if="['header'].includes( active_step )"
													:site="site" 
													label="Menu" 
													:block_key="'aaio/' + active_step"
													attr_key="menu_type"
													:options="menus"
													@success="(e) => update_setting( 'aaio/' + active_step, 'menu_type', e )" 
													/> -->
													<color_dropdown 
													v-if="!['header'].includes( active_step )"
													:site="site" 
													label="Padding top" 
													:block_key="'aaio/' + active_step"
													attr_key="block_padding_top"
													:options="padding_tops"
													@success="(e) => update_setting( 'aaio/' + active_step, 'block_padding_top', e )" 
													/>
													<color_dropdown 
													v-if="!['header'].includes( active_step )"
													:site="site" 
													label="Padding bottom" 
													:block_key="'aaio/' + active_step"
													attr_key="block_padding_bottom"
													:options="padding_bottoms"
													@success="(e) => update_setting( 'aaio/' + active_step, 'block_padding_bottom', e )" 
													/>
												</div>
											</div>
										</div>

										<div class="h-100 d-flex align-items-center">
											<span class="me-2 small">Zoom: {{ Math.round( zoom ) }}%</span>

											<button @click="toggle_active_screen(-1)" class="btn h-100 d-flex align-items-center px-4">
												<span class="material-icons-outlined">west</span>
											</button>

											<button @click="toggle_active_screen(1)" class="btn h-100 d-flex align-items-center px-4">
												<span class="material-icons-outlined">east</span>
											</button>
										</div>
									</div>
									<div class="w-100 iframe-container overflow-hidden position-relative" style="height: calc( 100vh - 170px );">
										<div v-if="!iframe_loaded" class="position-absolute top-50 start-50 translate-middle">
											<span class="spinner-border" role="status">
												<span class="visually-hidden">Loading...</span>
											</span>
										</div>
										<div class="h-100" style="overflow: hidden; overflow-y: auto;" ref="iframeContainer">
											<div :style="'height: ' + iframe_container_height + '; position: relative'">
												<iframe
												v-on:load="load_iframe"
												:src="preview"
												name="preview-iframe" 
												class="mx-auto d-block iframe overflow-hidden"
												:class="!iframe_loaded ? 'opacity-0' : ''"
												:style="['width:' + iframe_width, transform, 'height:' + iframe_height]"
												:scrolling="iframe_height && iframe_height != '100%' ? 'no' : ''"
												></iframe>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>

	</div>

	<div v-if="site && site.id" class="modal fade" id="success" tabindex="-1" aria-labelledby="successLabel" aria-hidden="true">

		<confetti />

		<div class="modal-dialog modal-dialog-centered" style="z-index: 10">
			<div class="modal-content overflow-hidden">
				<div class="modal-body p-0">
					<div class="card mb-0 bg-primary rounded-0 text-white border-0 position-relative">

						<div class="card-body pe-4 p-0 d-flex align-items-center justify-content-start">

							<div class="me-2">
								<div class="mx-auto overflow-hidden" style="width: 250px; height: 250px;">
									<img src="@/assets/img/leo.png" class="img-fluid">
								</div>
							</div>

							<div>
								<h2 class="h3">All done!</h2>
								<p class="mb-0">When you're ready you can go to your prototype and view the site we designed.</p>
								
							</div>

						</div>
					</div>
				</div>
				<div class="modal-footer justify-content-between bg-primary" style="border-color: rgba( 255, 255, 255, 0.2 )">
					<btn_router_link 
						label="Go to prototype" 
						icon="east"  
						:icon_after="true" 
						:loading="loading"
						:route="{ name: 'site.design.prototype', params: { site_id: site.id } }"
						@click="hide_modal"
						btn_class="d-inline-flex justify-content-between align-items-center btn btn-lg btn-outline-secondary w-100"
					/>
				</div>
			</div>
		</div>
	</div>

	<div v-if="site && site.id" class="modal fade" id="skip" tabindex="-1" aria-labelledby="skipLabel" aria-hidden="true">

		<div class="modal-dialog modal-dialog-centered" style="z-index: 10">
			<div class="modal-content overflow-hidden">
				<div class="modal-body p-0">
					<div class="card mb-0 bg-primary rounded-0 text-white border-0 position-relative">

						<div class="card-body pe-4 p-0 d-flex align-items-center justify-content-start">

							<div class="me-2">
								<div class="mx-auto overflow-hidden" style="width: 250px; height: 250px;">
									<img src="@/assets/img/leo.png" class="img-fluid">
								</div>
							</div>

							<div>
								<h2 class="h3">Are you sure?</h2>
								<p class="mb-0">If you skip I won't be able to import all of your content at once. You will need to import it for each page.</p>
								
							</div>

						</div>
					</div>
				</div>
				<div class="modal-footer justify-content-between bg-primary" style="border-color: rgba( 255, 255, 255, 0.2 )">
					<btn_submit 
						label="Yes, skip wizard" 
						icon="east"  
						:icon_after="true" 
						:loading="loading"
						@click="skip_wizard"
						btn_class="d-inline-flex justify-content-between align-items-center btn btn-lg btn-outline-secondary w-100"
					/>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import { mapGetters } from 'vuex';
import chat_message from '@/components/chat_message'
import color_dropdown from '@/components/color_dropdown'
import btn_submit from '@/components/btn_submit'
import confetti from '@/components/confetti'
import btn_router_link from '@/components/btn_router_link'
import planning_page from '@/components/planning_page'
import site_service from '@/services/site_service'
import team_service from '@/services/team_service'

export default {
	name: 'site.design_wizard',

	components: {
		chat_message,
		color_dropdown,
		btn_submit,
		btn_router_link,
		planning_page,
		confetti,
	},

	props: {
		modelValue: [Object],
		workspace: [Object],
		is_planning_complete: Boolean,
		is_design_complete: Boolean,
		is_build_complete: Boolean,
		is_launch_complete: Boolean
	},

	emits: [
		'update:modelValue',
	],

	data() {
		return {
			loading: false,
			site_loading: false,
			workspace_loading: true,
			iframe_loaded: false,
			iframe_posted: false,
			active_step: 'brand_logos',
			include_countdown: false,
			client_name: null,
			url: null,
			error: {},
			total: 23,
			team: null,
			site: null,
			active_screen: 0,
			active_setting: '',
			sub_menu: null,
			version: 1,
			colors: [
				{
					name: 'Default',
					value: '',
				},
				{
					name: 'Primary',
					value: 'primary',
				},
				{
					name: 'Secondary',
					value: 'secondary',
				},
				{
					name: 'Dark',
					value: 'dark',
				},
				{
					name: 'Light',
					value: 'light',
				},
				{
					name: 'Success',
					value: 'success',
				},
				{
					name: 'Warning',
					value: 'warning',
				},
				{
					name: 'Danger',
					value: 'danger',
				},
				{
					name: 'Info',
					value: 'info',
				},
				{
					name: 'White',
					value: 'white',
				},
			],
			dividers: [
				{
					name: 'Default',
					key: '',
				},
				{
					name: 'Angle',
					key: 'angle',
				},
				{
					name: 'Angles',
					key: 'angles',
				},
				{
					name: 'Curved',
					key: 'curved',
				},
				{
					name: 'Jagged',
					key: 'jagged',
				},
				{
					name: 'Paint',
					key: 'paint',
				},
				{
					name: 'Rounded',
					key: 'rounded',
				},
				{
					name: 'Wavy',
					key: 'wavy',
				},
				{
					name: 'Zigzag',
					key: 'zigzag',
				},
			],
			styles: [
				{
					name: 'Default',
					key: '',
				},
				{
					name: 'Squared',
					key: 'aaio-style-squared',
				},
				{
					name: 'Rounded',
					key: 'aaio-style-rounded',
				},
				{
					name: 'Angled',
					key: 'aaio-style-angled',
				},
				{
					name: 'Soft shadow',
					key: 'aaio-style-soft-shadow',
				},
				{
					name: 'Hard shadow',
					key: 'aaio-style-hard-shadow',
				},
			],
			containers: [
				{
					name: 'Default',
					value: '',
				},
				{
					name: 'Fixed',
					value: 'aaio-container-fixed',
				},
				{
					name: 'Wide',
					value: 'aaio-container-lg',
				},
				{
					name: 'Full Width',
					value: 'aaio-container-fluid',
				},
			],
			menus: [
				{
					name: 'Default',
					value: '',
				},
				{
					name: 'Mega Menu',
					value: 'mega',
				},
				{
					name: 'Mega Menu Carousel',
					value: 'mega-carousel',
				},
				{
					name: 'Fullscreen',
					value: 'fullscreen',
				},
				{
					name: 'Fullscreen Carousel',
					value: 'fullscreen-carousel',
				},
			],
			padding_tops: [
				{
					name: 'Default',
					value: '',
				},
				{
					name: 'None',
					value: 'aaio-pt-none',
				},
				{
					name: 'Small',
					value: 'aaio-pt-sm',
				},
				{
					name: 'Medium',
					value: 'aaio-pt-md',
				},
				{
					name: 'Large',
					value: 'aaio-pt-lg',
				},
				{
					name: 'Extra Large',
					value: 'aaio-pt-xl',
				},
			],
			padding_bottoms: [
				{
					name: 'Default',
					value: '',
				},
				{
					name: 'None',
					value: 'aaio-pb-none',
				},
				{
					name: 'Small',
					value: 'aaio-pb-sm',
				},
				{
					name: 'Medium',
					value: 'aaio-pb-md',
				},
				{
					name: 'Large',
					value: 'aaio-pb-lg',
				},
				{
					name: 'Extra Large',
					value: 'aaio-pb-xl',
				},
			],
			color_modes: [
				{
					name: 'Alternate',
					key: 'aaio-mode-alternate',
				},
				{
					name: 'Alternate Dark',
					key: 'aaio-mode-alternate-dark',
				},
				{
					name: 'Alternate Light',
					key: 'aaio-mode-alternate-light',
				},
				{
					name: 'White',
					key: 'aaio-mode-white',
				},
				{
					name: 'Primary',
					key: 'aaio-mode-primary',
				},
				{
					name: 'Secondary',
					key: 'aaio-mode-secondary',
				},
				{
					name: 'Dark',
					key: 'aaio-mode-dark',
				},
				{
					name: 'Light',
					key: 'aaio-mode-light',
				},
				{
					name: 'Success',
					key: 'aaio-mode-success',
				},
				{
					name: 'Warning',
					key: 'aaio-mode-warning',
				},
				{
					name: 'Danger',
					key: 'aaio-mode-danger',
				},
				{
					name: 'Info',
					key: 'aaio-mode-info',
				},
			],
			headers: [
				{
					name: 'Header 1',
					key: 'header-1',
				},
				{
					name: 'Header 2',
					key: 'header-2',
				},
				{
					name: 'Header 3',
					key: 'header-3',
				},
				{
					name: 'Header 4',
					key: 'header-4',
				},
				{
					name: 'Header 5',
					key: 'header-5',
				},
				{
					name: 'Header 6',
					key: 'header-6',
				},
			],
			heros: [
				{
					name: 'Hero 1',
					key: 'hero-1',
				},
				{
					name: 'Hero 2',
					key: 'hero-12',
				},
				{
					name: 'Hero 3',
					key: 'hero-15',
				},
				{
					name: 'Hero 4',
					key: 'hero-16',
				},
				{
					name: 'Hero 5',
					key: 'hero-17',
				},
				{
					name: 'Hero 6',
					key: 'hero-26',
				},
				{
					name: 'Hero 7',
					key: 'hero-27',
				},
				{
					name: 'Hero 8',
					key: 'hero-28',
				},
				{
					name: 'Hero 9',
					key: 'hero-4',
				},
				{
					name: 'Hero 10',
					key: 'hero-7',
				},
				{
					name: 'Hero 11',
					key: 'hero-8',
				},
			],
			contents: [
				{
					name: 'Content 1',
					key: 'content-1',
				},
				{
					name: 'Content 2',
					key: 'content-2',
				},
				{
					name: 'Content 3',
					key: 'content-7',
				},
				{
					name: 'Content 4',
					key: 'content-8',
				},
				{
					name: 'Content 5',
					key: 'content-15',
				},
				{
					name: 'Content 6',
					key: 'content-19',
				},
			],
			accordions: [
				{
					name: 'Accordion 1',
					key: 'accordion-1',
				},
				{
					name: 'Accordion 2',
					key: 'accordion-2',
				},
				{
					name: 'Accordion 3',
					key: 'accordion-3',
				},
				{
					name: 'Accordion 4',
					key: 'accordion-4',
				},
				{
					name: 'Accordion 5',
					key: 'accordion-5',
				},
				{
					name: 'Accordion 6',
					key: 'accordion-6',
				},
			],
			carousels: [
				{
					name: 'Carousel 1',
					key: 'carousel-1',
				},
				{
					name: 'Carousel 2',
					key: 'carousel-10',
				},
				{
					name: 'Carousel 3',
					key: 'carousel-11',
				},
				{
					name: 'Carousel 4',
					key: 'carousel-12',
				},
				{
					name: 'Carousel 5',
					key: 'carousel-13',
				},
				{
					name: 'Carousel 6',
					key: 'carousel-14',
				},
				{
					name: 'Carousel 7',
					key: 'carousel-15',
				},
				{
					name: 'Carousel 8',
					key: 'carousel-16',
				},
				{
					name: 'Carousel 9',
					key: 'carousel-17',
				},
				{
					name: 'Carousel 10',
					key: 'carousel-18',
				},
				{
					name: 'Carousel 11',
					key: 'carousel-2',
				},
				{
					name: 'Carousel 12',
					key: 'carousel-3',
				},
				{
					name: 'Carousel 13',
					key: 'carousel-4',
				},
				{
					name: 'Carousel 14',
					key: 'carousel-5',
				},
				{
					name: 'Carousel 15',
					key: 'carousel-6',
				},
				{
					name: 'Carousel 16',
					key: 'carousel-7',
				},
				{
					name: 'Carousel 17',
					key: 'carousel-8',
				},
				{
					name: 'Carousel 18',
					key: 'carousel-9',
				},
			],
			contacts: [
				{
					name: 'Contact 1',
					key: 'contact-1',
				},
				{
					name: 'Contact 2',
					key: 'contact-2',
				},
				{
					name: 'Contact 3',
					key: 'contact-3',
				},
				{
					name: 'Contact 4',
					key: 'contact-4',
				},
			],
			ctas: [
				{
					name: 'CTA 1',
					key: 'cta-1',
				},
				{
					name: 'CTA 2',
					key: 'cta-2',
				},
				{
					name: 'CTA 3',
					key: 'cta-3',
				},
				{
					name: 'CTA 4',
					key: 'cta-4',
				},
				{
					name: 'CTA 5',
					key: 'cta-5',
				},
				{
					name: 'CTA 6',
					key: 'cta-6',
				},
				{
					name: 'CTA 7',
					key: 'cta-7',
				},
			],
			forms: [
				{
					name: 'Form 1',
					key: 'form-1',
				},
				{
					name: 'Form 2',
					key: 'form-2',
				},
				{
					name: 'Form 3',
					key: 'form-3',
				},
				{
					name: 'Form 4',
					key: 'form-4',
				},
				{
					name: 'Form 5',
					key: 'form-5',
				},
			],
			itemss: [
				{
					name: 'Items 1',
					key: 'items-1',
				},
				{
					name: 'Items 2',
					key: 'items-13',
				},
				{
					name: 'Items 3',
					key: 'items-14',
				},
				{
					name: 'Items 4',
					key: 'items-20',
				},
				{
					name: 'Items 5',
					key: 'items-21',
				},
				{
					name: 'Items 6',
					key: 'items-22',
				},
				{
					name: 'Items 7',
					key: 'items-25',
				},
				{
					name: 'Items 8',
					key: 'items-28',
				},
				{
					name: 'Items 9',
					key: 'items-32',
				},
				{
					name: 'Items 10',
					key: 'items-34',
				},
				{
					name: 'Items 11',
					key: 'items-5',
				},
				{
					name: 'Items 12',
					key: 'items-6',
				},
			],
			logoss: [
				{
					name: 'Logos 1',
					key: 'logos-1',
				},
				{
					name: 'Logos 2',
					key: 'logos-2',
				},
				{
					name: 'Logos 3',
					key: 'logos-3',
				},
			],
			medias: [
				{
					name: 'Media 1',
					key: 'media-1',
				},
				{
					name: 'Media 2',
					key: 'media-2',
				},
				{
					name: 'Media 3',
					key: 'media-3',
				},
				{
					name: 'Media 4',
					key: 'media-4',
				},
			],
			peoples: [
				{
					name: 'People 1',
					key: 'people-10',
				},
				{
					name: 'People 2',
					key: 'people-12',
				},
			],
			posts_carousels: [
				{
					name: 'Posts carousel 1',
					key: 'posts-carousel-1',
				},
				{
					name: 'Posts carousel 2',
					key: 'posts-carousel-2',
				},
				{
					name: 'Posts carousel 3',
					key: 'posts-carousel-3',
				},
				{
					name: 'Posts carousel 4',
					key: 'posts-carousel-4',
				},
				{
					name: 'Posts carousel 5',
					key: 'posts-carousel-5',
				},
				{
					name: 'Posts carousel 6',
					key: 'posts-carousel-6',
				},
			],
			posts_grids: [
				{
					name: 'Posts grid 1',
					key: 'posts-grid-1',
				},
				{
					name: 'Posts grid 2',
					key: 'posts-grid-2',
				},
				{
					name: 'Posts grid 3',
					key: 'posts-grid-3',
				},
				{
					name: 'Posts grid 4',
					key: 'posts-grid-4',
				},
				{
					name: 'Posts grid 5',
					key: 'posts-grid-5',
				},
				{
					name: 'Posts grid 6',
					key: 'posts-grid-6',
				},
				{
					name: 'Posts grid 7',
					key: 'posts-grid-7',
				},
				{
					name: 'Posts grid 8',
					key: 'posts-grid-8',
				},
			],
			tabss: [
				{
					name: 'Tabs 1',
					key: 'tabs-1',
				},
				{
					name: 'Tabs 2',
					key: 'tabs-2',
				},
				{
					name: 'Tabs 3',
					key: 'tabs-3',
				},
			],
			footers: [
				{
					name: 'Footer 1',
					key: 'footer-1',
				},
				{
					name: 'Footer 2',
					key: 'footer-2',
				},
				{
					name: 'Footer 3',
					key: 'footer-3',
				},
			],
			zoom: 50,
			iframe_height: '100%',
			scroll_height: null,
			ratio: 1,
			block_tops: []
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		first_name()
		{
			if ( this.auth_user.name ) {
				const nameParts = this.auth_user.name.split(' ');
				return nameParts[0]
			}

			return ''
		},

		count()
		{
			var count = 1;

			if (this.site?.settings) {
				const steps = [
					'brand_logos_complete',
					'colors_complete',
					'fonts_complete',
					'color_mode_complete',
					'divider_complete',
					'style_complete',
					'header_complete',
					'hero_complete',
					'content_complete',
					'accordion_complete',
					'carousel_complete',
					'block_contact_complete',
					'cta_complete',
					'form_complete',
					'items_complete',
					'logos_complete',
					'media_complete',
					// 'people_complete',
					'posts-carousel_complete',
					'posts-grid_complete',
					'tabs_complete',
					'footer_complete',
					'design_complete'
				];

				count += steps.reduce((acc, step) => acc + (this.site.settings[step] ? 1 : 0), 0);
			}

			return count;
		},

		percent()
		{
			return ( ( this.count / this.total ) * 100 ).toFixed( 0 );
		},

		screens()
		{
			
			if ( this.active_step == 'color_mode' ) {
				return this.color_modes
			}
			if ( this.active_step == 'divider' ) {
				return this.dividers
			}
			if ( this.active_step == 'style' ) {
				return this.styles
			}
			if ( this.active_step == 'header' ) {
				return this.headers
			}
			if ( this.active_step == 'hero' ) {
				return this.heros
			}
			if ( this.active_step == 'content' ) {
				return this.contents
			}
			if ( this.active_step == 'accordion' ) {
				return this.accordions
			}
			if ( this.active_step == 'carousel' ) {
				return this.carousels
			}
			if ( this.active_step == 'block_contact' ) {
				return this.contacts
			}
			if ( this.active_step == 'cta' ) {
				return this.ctas
			}
			if ( this.active_step == 'form' ) {
				return this.forms
			}
			if ( this.active_step == 'items' ) {
				return this.itemss
			}
			if ( this.active_step == 'logos' ) {
				return this.logoss
			}
			if ( this.active_step == 'media' ) {
				return this.medias
			}
			/*if ( this.active_step == 'people' ) {
				return this.peoples
			}*/
			if ( this.active_step == 'posts-carousel' ) {
				return this.posts_carousels
			}
			if ( this.active_step == 'posts-grid' ) {
				return this.posts_grids
			}
			if ( this.active_step == 'tabs' ) {
				return this.tabss
			}
			if ( this.active_step == 'footer' ) {
				return this.footers
			}

			return []
		},

		preview()
		{
			const base_url = 'https://preview.areoi.io/?v=' + this.version + '#' + this.active_step;		

			return base_url;
		},

		iframe_width()
		{
			var width = '1600px';

			return width
		},

		iframe_container_height()
		{
			var height = '100%';

			var scale = this.zoom / 100

			if ( this.zoom < 100 ) {
				height = ( this.scroll_height * scale ) + 'px';
			}

			return height;
		},

		transform()
		{
			var scale = this.zoom / 100  

			return 'transform: scale(' + scale + '); transform-origin: top left;'
		},

	},

	mounted()
	{
		this.site = JSON.parse( JSON.stringify( this.modelValue ) )

		this.set_active()

		this.navigate_scroll()

		this.get_team()

		var vm = this;
		var $ = window.$
		$(window).on('message', function(event) {
			var data = event.originalEvent.data;
			
			if ( data.origin === 'areoi-site' ) {
				vm.scroll_height = data.height
				vm.block_tops = data.block_tops
				
				if ( vm.zoom < 100 ) {
					vm.iframe_height = data.height + 'px';
				} else {
					vm.iframe_height = '100%';
				}

				vm.trigger_message();	
			}
		});	

		window.addEventListener('resize', this.trigger_message);

		this.trigger_message();
	},

	beforeUnmount() 
	{
		window.removeEventListener('resize', this.trigger_message);
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		async get_team()
		{
			this.reset_error()

			team_service.show( this.auth_user.team_id ).then(( response ) => {
				this.team = response.data
			}).catch( ( error ) => {
				this.error = error
			})
		},

		set_active() 
		{
			const steps = [
				{ key: 'brand_logos', complete: 'brand_logos_complete' },
				{ key: 'colors', complete: 'colors_complete' },
				{ key: 'fonts', complete: 'fonts_complete' },
				{ key: 'color_mode', complete: 'color_mode_complete', block: 'color-mode' },
				{ key: 'divider', complete: 'divider_complete', block: 'divider' },
				{ key: 'style', complete: 'style_complete', block: 'style' },
				{ key: 'header', complete: 'header_complete', block: 'aaio/header' },
				{ key: 'hero', complete: 'hero_complete', block: 'aaio/hero' },
				{ key: 'content', complete: 'content_complete', block: 'aaio/content' },
				{ key: 'accordion', complete: 'accordion_complete', block: 'aaio/accordion' },
				{ key: 'carousel', complete: 'carousel_complete', block: 'aaio/carousel' },
				{ key: 'block_contact', complete: 'block_contact_complete', block: 'aaio/contact' },
				{ key: 'cta', complete: 'cta_complete', block: 'aaio/cta' },
				{ key: 'form', complete: 'form_complete', block: 'aaio/form' },
				{ key: 'items', complete: 'items_complete', block: 'aaio/items' },
				{ key: 'logos', complete: 'logos_complete', block: 'aaio/logos' },
				{ key: 'media', complete: 'media_complete', block: 'aaio/media' },
				// { key: 'people', complete: 'people_complete', block: 'aaio/people' },
				{ key: 'posts-carousel', complete: 'posts-carousel_complete', block: 'aaio/posts-carousel' },
				{ key: 'posts-grid', complete: 'posts-grid_complete', block: 'aaio/posts-grid' },
				{ key: 'tabs', complete: 'tabs_complete', block: 'aaio/tabs' },
				{ key: 'footer', complete: 'footer_complete', block: 'aaio/footer' },
				{ key: 'complete', complete: 'design_complete', block: 'complete' },
			];

			this.active_step = 'brand_logos';

			for (const step of steps) {
				this.active_step = step.key;
				this.active_screen = 0;

				if (step.block && this.site.settings['blocks'] && this.site.settings['blocks'][step.block]) {
					this.active_screen = this.screens.findIndex(item => item.key === this.site.settings['blocks'][step.block].template);
				} else if (step.block && this.site.settings && this.site.settings[step.block]) {
					this.active_screen = this.screens.findIndex(item => item.key === this.site.settings[step.block]);
				}

				if ( !this.site.settings[step.complete] ) {
					break;
				}
			}

			if ( this.site.settings['design_complete'] || this.site.settings['skip_design_wizard_complete'] ) {
				this.active_step = 'complete'
				this.hide_modal()
				this.$router.push( { name: 'site.design.prototype' } )
			}
		},

		hide_modal()
		{
			window.$( '.modal' ).modal( 'hide' );
		},

		navigate_scroll()
		{
			this.$nextTick(() => {
				this.scrollToBottom();
				this.focusInput();
			});
		},

		submit()
		{
			this.reset_error()

			const stepMapping = {
				'brand_logos': 'colors',
				'colors': 'fonts',
				'fonts': 'color_mode',
				'color_mode': 'divider',
				'divider': 'style',
				'style': 'header',
				'header': 'hero',
				'hero': 'content',
				'content': 'accordion',
				'accordion': 'carousel',
				'carousel': 'block_contact',
				'block_contact': 'cta',
				'cta': 'form',
				'form': 'items',
				'items': 'logos',
				'logos': 'media',
				'media': 'posts-carousel',
				// 'people': 'posts-carousel',
				'posts-carousel': 'posts-grid',
				'posts-grid': 'tabs',
				'tabs': 'footer',
				'footer': 'complete',
				'complete': 'complete'
			};

			if (stepMapping[this.active_step]) {
				this.update(stepMapping[this.active_step]);
			}

			this.navigate_scroll()
		},

		async update( next_step )
		{
			this.loading = true 

			this.reset_error()

			this.site.question = this.active_step

			site_service.update_wizard( this.$route.params.workspace_id, this.$route.params.site_id, this.site ).then(( response ) => {
				this.site = response.data
				// this.active_step = next_step
				console.log(next_step)
				this.set_active()
				this.version++

				this.iframe_loaded = false;
				this.iframe_posted = false;
				this.sub_menu = null

				this.$emit('update:modelValue', this.site)

			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.loading = false
				this.navigate_scroll()
			})
		},

		async skip_wizard()
		{
			this.loading = true 

			this.reset_error()

			this.site.question = 'skip_design_wizard'
			this.hide_modal()
			site_service.update_wizard( this.$route.params.workspace_id, this.$route.params.site_id, this.site ).then(( response ) => {
				
				this.site = response.data

				this.$emit('update:modelValue', this.site)

				setTimeout( () => {
					this.$router.push( { name: 'site.design.prototype', params: { site_id: this.site.id } } )
				}, 100)
				
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.loading = false
				this.navigate_scroll()
			})
		},

		scrollToBottom() 
		{
			const container = this.$refs.scrollContainer;
			if (container) {
				container.scrollTop = container.scrollHeight;
			}
		},

		focusInput() 
		{
			window.$( '.setup-chat-actions input' ).focus();
		},

		load_iframe()
		{
			if ( this.iframe_posted ) {
				this.iframe_loaded = true;
				setTimeout( () => {
					this.trigger_message( true );
				}, 100);
			} else {
				this.post_to_iframe();
				this.iframe_posted = true;
			}
		},

		set_settings()
		{
			
			if ( !this.site.settings['blocks'] ) {
				this.site.settings['blocks'] = {}
			}

			if ( this.active_step == 'color_mode' ) {
				this.site.settings['color-mode'] = this.screens[this.active_screen].key
			}
			if ( this.active_step == 'divider' ) {
				this.site.settings['divider'] = this.screens[this.active_screen].key
			}
			if ( this.active_step == 'style' ) {
				this.site.settings['style'] = this.screens[this.active_screen].key
			}

			if ( ![ 'color_mode', 'divider', 'style' ].includes( this.active_step ) ) {

				var step = ( this.active_step == 'block_contact' ? 'contact' : this.active_step )
				
				if ( !this.site.settings['blocks']['aaio/' + step] ) {
					this.site.settings['blocks']['aaio/' + step] = {}
				}
				this.site.settings['blocks']['aaio/' + step] = {
					template: this.screens[this.active_screen].key,
					block_theme: this.site.settings['blocks']['aaio/' + step]['block_theme'],
					block_text: this.site.settings['blocks']['aaio/' + step]['block_text'],
					block_heading: this.site.settings['blocks']['aaio/' + step]['block_heading'],
					block_buttons: this.site.settings['blocks']['aaio/' + step]['block_buttons'],
					block_container: this.site.settings['blocks']['aaio/' + step]['block_container'],
					block_padding_top: this.site.settings['blocks']['aaio/' + step]['block_padding_top'],
					block_padding_bottom: this.site.settings['blocks']['aaio/' + step]['block_padding_bottom'],
					menu_type: this.site.settings['blocks']['aaio/' + step]['menu_type'],
				}
			}
			
			this.post_to_iframe()
		},

		toggle_active_screen( increment )
		{
			this.sub_menu = null;

			this.iframe_loaded = false;

			if ( increment == -1 ) {
				
				if ( ( this.active_screen - 1 ) >= 0 ) {
					this.active_screen--
				} else {
					this.active_screen = ( this.screens.length - 1 )
				}
			} else {
				if ( this.screens.length > ( this.active_screen + increment ) ) {
					this.active_screen++
				} else {
					this.active_screen = 0
				}
			}

			this.set_settings()
			
		},

		set_active_screen( key )
		{
			this.sub_menu = null;

			this.iframe_loaded = false;

			this.active_screen = key

			this.set_settings()
			
		},

		set_active_step( key )
		{
			this.sub_menu = null;

			// this.iframe_loaded = false;

			this.active_step = key	
			
			if ( !['brand_logos', 'colors', 'fonts'].includes( this.active_step ) ) {

				var step = ( this.active_step == 'block_contact' ? 'contact' : ( this.active_step == 'color_mode' ? 'color-mode' : this.active_step ) )

				if ( [ 'color_mode', 'style', 'divider' ].includes( this.active_step ) && this.site.settings[step] ) {
					this.active_screen = this.screens.findIndex(item => item.key === this.site.settings[step]);
				} else if ( this.site.settings['blocks'] && this.site.settings['blocks']['aaio/' + step] && this.site.settings['blocks']['aaio/' + step].template ) {
					this.active_screen = this.screens.findIndex(item => item.key === this.site.settings['blocks']['aaio/' + step].template);
				}

				setTimeout( () => {
					this.post_to_iframe()
				}, 100)
			}
		},

		update_setting( group, key, value )
		{
			this.iframe_loaded = false;
			
			if ( !this.site.settings['blocks'] ) {
				this.site.settings['blocks'] = {}
			}
			if ( !this.site.settings['blocks'][group] ) {
				this.site.settings['blocks'][group] = {}
			}

			this.site.settings['blocks'][group][key] = value

			this.set_settings()			
		},

		post_to_iframe() 
		{
			this.iframe_loaded = false;

			const form = document.createElement('form');
			form.setAttribute('method', 'post');
			form.setAttribute('target', 'preview-iframe');
			form.setAttribute('action', this.preview );
			
			if ( this.site.settings['blocks'] ) {
				
				const array = Object.entries(this.site.settings['blocks']);

				var obj = {};
				array.forEach(([key, value]) => {
					obj[key] = value;
				});

				this.site.settings['blocks'] = obj;
			}
			

			const data = JSON.stringify( this.site.settings );
			
			const input = document.createElement('input');
			input.type = 'hidden';
			input.name = 'settings';
			input.value = data;
			form.appendChild(input);

			document.body.appendChild(form);
			form.submit();

			document.body.removeChild(form);
		},

		trigger_message( is_post = false )
		{
			var $ = window.$;

			var container = $('.iframe-container');
			
			if ( container.length ) {

				var width = container.width() - 15;
				var iframe_width = parseInt( this.iframe_width.replace('px') );
				this.ratio = ( iframe_width < width ? iframe_width / width : width / iframe_width );
				this.zoom = this.ratio * 100;
				var scale = this.zoom / 100;

				if ( is_post ) {
					$( '.iframe' )[0].contentWindow.postMessage( { 
						origin: 'areoi-platform',
						zoom: this.zoom
					}, this.preview );
				}
				
				container = this.$refs.iframeContainer;
				if ( container ) {
					var targetScrollTop = 0;
					if ( this.block_tops[this.active_step] ) {
						targetScrollTop = ( this.block_tops[this.active_step] + 75 ) * scale;
					}

					container.scrollTop = targetScrollTop
				}
			}
			
		}
	}
}
</script>

<style>
	.setup-chat-actions .form-control,
	.setup-chat-actions .form-select {
		height: 100%;
		border: none;
		border-radius: 0;
		font-size: 1.2rem;
	}
	.iframe {
		transition: opacity 0.5s ease-in-out 1s;
	}
	.iframe.opacity-0 {
		transition: none;
	}
</style>
